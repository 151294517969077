@import 'jqueryui';
@import 'plyr';
@import 'slick';

//Fonts
@font-face {
    font-family: 'Effra';
    src: url('../fonts/Effra-Regular.eot');
    src: url('../fonts/Effra-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Effra-Regular.woff2') format('woff2'),
        url('../fonts/Effra-Regular.woff') format('woff'),
        url('../fonts/Effra-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Effra';
    src: url('../fonts/Effra-Bold.eot');
    src: url('../fonts/Effra-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Effra-Bold.woff2') format('woff2'),
        url('../fonts/Effra-Bold.woff') format('woff'),
        url('../fonts/Effra-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Effra';
    src: url('../fonts/Effra-Heavy.eot');
    src: url('../fonts/Effra-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Effra-Heavy.woff2') format('woff2'),
        url('../fonts/Effra-Heavy.woff') format('woff'),
        url('../fonts/Effra-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

*{
	backface-visibility: hidden;
}

@font-face {
  font-family: 'FrutigerLTArabic';
  src: url('../fonts/FrutigerLTArabic-45Light.eot');
  src: url('../fonts/FrutigerLTArabic-45Light.woff2') format('woff2'),
       url('../fonts/FrutigerLTArabic-45Light.woff') format('woff'),
       url('../fonts/FrutigerLTArabic-45Light.ttf') format('truetype'),
       url('../fonts/FrutigerLTArabic-45Light.svg#FrutigerLTArabic-45Light') format('svg'),
       url('../fonts/FrutigerLTArabic-45Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'FrutigerLTArabic';
  src: url('../fonts/FrutigerLTArabic-55Roman.eot');
  src: url('../fonts/FrutigerLTArabic-55Roman.woff2') format('woff2'),
       url('../fonts/FrutigerLTArabic-55Roman.woff') format('woff'),
       url('../fonts/FrutigerLTArabic-55Roman.ttf') format('truetype'),
       url('../fonts/FrutigerLTArabic-55Roman.svg#FrutigerLTArabic-55Roman') format('svg'),
       url('../fonts/FrutigerLTArabic-55Roman.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'FrutigerLTArabic';
  src: url('../fonts/FrutigerLTArabic-65Bold.eot');
  src: url('../fonts/FrutigerLTArabic-65Bold.woff2') format('woff2'),
       url('../fonts/FrutigerLTArabic-65Bold.woff') format('woff'),
       url('../fonts/FrutigerLTArabic-65Bold.ttf') format('truetype'),
       url('../fonts/FrutigerLTArabic-65Bold.svg#FrutigerLTArabic-65Bold') format('svg'),
       url('../fonts/FrutigerLTArabic-65Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTArabic';
  src: url('../fonts/FrutigerLTArabic-75Black 2.eot');
  src: url('../fonts/FrutigerLTArabic-75Black 2.woff2') format('woff2'),
       url('../fonts/FrutigerLTArabic-75Black 2.eot?#iefix') format('embedded-opentype'),
       url('../fonts/FrutigerLTArabic-75Black.woff') format('woff'),
       url('../fonts/FrutigerLTArabic-75Black.ttf') format('truetype'),
       url('../fonts/FrutigerLTArabic-75Black.svg#FrutigerLTArabic-75Black') format('svg');
  font-weight: 900;
  font-style: normal;
}






$animatedButtonWidth: 40px;
$animatedButtonHeight: 38px;

$headerYpad: 80px;
$headerXpad:  80px;

$headerYpad1: 70px;
$headerXpad1:  140px;

$headerYpad2: 60px;
$headerXpad2:  120px;

$headerYpad3: 50px;
$headerXpad3:  100px;

$headerYpad4: 40px;
$headerXpad4:  80px;

$headerYpad5: 30px;
$headerXpad5:  60px;

$headerYpad6: 30px;
$headerXpad6: 20px;

$menuiconHeight: 37px;
$menuiconHeight1: 27px;


//Variable
// Set Colors
$white:				#FFFFFF !default;
$black: 			#000000 !default;
$gray: 		   		#cacaca !default;

//circle
$fontEN: 'Effra';
$fontAR: 'FrutigerLTArabic';

//Media Sizes
//widthQuries
$desktop1600: 		"(max-width: 1919px)";
$desktop1440: 		"(max-width: 1599px)";
$desktop1280: 		"(max-width: 1439px)";
$tabletlandscape: 	"(max-width: 1199px)";
$tabletportrait: 	"(max-width: 991px)";
$mobilelarge: 		"(max-width: 778px)";
$mobilemidium: 		"(max-width: 374px)";
$mobilesmall: 		"(max-width: 359px)";

//Base Styling
*{
    outline: none !important;
    box-sizing: border-box
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: normal;
}
p{
    margin: 0;
    margin-bottom: 10px;
    @media #{$desktop1440}{
    }
    @media #{$desktop1280}{
    }
}
strong{
	font-weight: bold;
}
a{
    text-decoration: none;
    color: $black;
}
img{
    max-width: 100%;
}
//custom styling

html,
body {
	font-size: 18px;
	line-height: 1.3;
	margin: 0;
	padding: 0;
	background-color: $white;
	color: $black;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	font-family: $fontEN
}


/* ==================================== */
/* Popup CSS */
/* ==================================== */

/* .c-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
	.overlay {
	    display: block;
	    height: 100%;
	    width: 100%;
	    background: transparent;
	    transition: all .3s;
	}
	&.popup--open .overlay {
	    background: rgba(0, 0, 0, 0.5);
	}
	.popup {
	    display: none;
	    position: absolute;
	    left: 50%;
	    -webkit-transform: translate(-50%, -50%);
	    -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	    -o-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
	    max-width: 740px;
	    padding: 20px;
	    max-height: 100%;
	    width: 100%;
	    opacity: 0;
	    top: 40%;
	    transition: all .3s 0s;
	    overflow-y: auto;
		&.popup--full-screen {
		    top: 0;
		    left: 0;
		    -webkit-transform: none;
		    -moz-transform: none;
		    -ms-transform: none;
		    -o-transform: none;
		    transform: none;
		    width: 100%;
		    height: 100%;
		    padding: 0;
		    max-width: none;
			&.active {
			    top: 0;
			}
			.popup-wrap {
			    padding: 0;
			    height: 100%;
				.plyr {
				    height: 100%;
				}
			}
			.popup-action {
			    position: absolute;
			    top: 0;
			    right: 0;
			    z-index: 999;
			    margin-right: 10px;
			    @media #{$tabletlandscape}{
			    	margin: 13px 18px 0 0;
			    }
			}
		}
		.popup-wrap {
		    background: #fff;
		    padding: 20px;
		}
		&.active {
		    top: 50%;
		    opacity: 1;
		    transition-delay: .3s;
		}
	}
	.title {
	    font-size: 1.5625em;
	    line-height: 1.3em;
	    margin: 0 0 15px;
	    font-weight: bold;
	    color: #000000;
	}
	.txt-wrap {
	    max-width: 500px;
	    margin: 0 auto;
	}
	.deco {
	    display: block;
	    margin: 5px 0 15px 0;
	    border: none;
	    position: relative;
	    height: 1px;
	    background: #9f9a9a;
		&:after {
		    content: '';
		    display: block;
		    width: 80px;
		    height: 6px;
		    background: #000000;
		    position: absolute;
		    bottom: 0px;
		    left: 20px;
		}
	}
	.popup-action {
	    text-align: center;
	    margin-top: 10px;
	}
	.js-video{
	    height: 100%;
	}
	video {
		width: 100%;
		height: 100%;
	}
} */
/* ==================================== */
/* END - Popup CSS */
/* ==================================== */
/* ==================================== */
/* Popup Close BUtton  */
/* ==================================== */

/* .c-close {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid #000000;
    border-radius: 999px;
    position: relative;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff;
    transition: all .3s;
    box-sizing: border-box;
	span {
	    display: none;
	}
	&:before,
	&:after {
	    content: '';
	    box-sizing: border-box;
	    display: block;
	    position: absolute;
	    top: 11px;
	    left: 15%;
	    height: 1px;
	    width: 70%;
	    background: #000000;
	    transition: all .3s;
	}
	&:after {
	    top: 15%;
	    left: 11px;
	    height: 70%;
	    width: 1px;
	}
	&:hover {
	    background: #000000;
	    -webkit-transform: rotate(135deg);
	    -moz-transform: rotate(135deg);
	    -ms-transform: rotate(135deg);
	    -o-transform: rotate(135deg);
	    transform: rotate(135deg);
		&:before,
		&:after {
		    background: #fff;
		}
	}
	&:active {
	    -webkit-transform: rotate(225deg);
	    -moz-transform: rotate(225deg);
	    -ms-transform: rotate(225deg);
	    -o-transform: rotate(225deg);
	    transform: rotate(225deg);
	}
} */

/* ==================================== */
/* END - Popup Close BUtton  */
/* ==================================== */
/* Some responsive css for close button and popup */

/* @media (max-width: 767px) {
    .c-close {
        // width: 20px;
        // height: 20px;
    }
    .c-close:after {
        // left: 9px;
    }
    .c-close:before {
        // top: 9px;
    }
    .c-popup .deco:after {
        width: 60px;
        height: 4px;
        left: 15px;
    }
    .c-popup .popup .popup-wrap {
        padding: 15px;
    }
    .popup .c-form-1 {
        position: static;
        margin: 0;
    }
    .popup .c-form-1 .wrap {
        padding: 0;
    }
    .popup .c-form-1 select,
    .popup .c-form-1 .select2-container {
        width: 100% !important;
    }
} */
/* This will keep the video to be complete visible rather than partially! */
/* .plyr__video-wrapper{
    height: 100%;
    padding: 0 !important;
} */

.o-hidden{
    overflow: hidden;
}

/* .container{
    width: 100%;
    margin: 0 auto;
    max-width: 1170px;
    padding-left: 15px; 
    padding-right: 15px; 
} */

/* .tosvg{
	&.svgGray{
		path{
			fill: $gray !important;
			stroke: none !important;
		}
	}
	&.svgWhite{
		path{
			fill:$white !important;
			stroke: none !important;
		}
	}
	&.svgBlack{
		path{
			fill: $black !important;
			stroke: none !important;
		}
	}
} */
.c-bg-carousel{
	&:before{
		content: '';
		/* display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 250px;
		background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		z-index: 1;
		opacity: 0.75; */
	}
	.slick-slide {
	    height: 100vh;
	}

	img{
		width: 100vw;
		height: 100vh;
		object-fit: cover;
	}
	.item{
		width: 100vw;
		height: 100vh;
		position: relative;
		overflow: hidden;

		&.add--play-icon{
			cursor: pointer;
			.icon{
				display: block;
				border-radius: 999px;
				width:  80px;
				height: 80px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) translateZ(0);
				background: rgba(255,255,255,0.4);
				z-index: 2;

				&:before{
					content: '';
				    display: block;
				    width: 0;
				    height: 0;
				    border-left:   15px solid #fff;
				    border-top:    15px solid transparent;
				    border-bottom: 15px solid transparent;
				    margin-right:  30px;
				    position: absolute;
				    left: 50%;
				    top: 50%;
				    margin: -15px 0 0 -4px;
				}
			}
		}

		&.add--video-icon{
			&:before{
				content: '';
				display: inline-block;
				width: 0;
				height: 0;
				border-left: 7px solid #fff;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
				margin-right: 15px;
				position: relative;
				left: 50%;
				top: 50%;
				transition: all 0.35s;
			}

			&:after{
				content: '';
				display: block;
				border-radius: 999px;
				background: rgba(255,255,255,0.5);
				width: 100px;
				height: 100px;
			}
		}

		&.has--video{
			@media (max-width: 1023px) {
				height: 80vh;
			}
		}
	}
}

.no-objectfit .c-bg-carousel img{
	width: 100vw;
	height: auto;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.carousel-nav{
	position: absolute;
	bottom: 17%;
	right: 0;
	background: #fff;
	padding: 20px 155px 20px 20px;
	z-index: 5;
	cursor: pointer;

	@media (max-width: 1820px) {
		padding: 5px 100px 5px 5px;
	}

	@media (max-width: 1420px) {
		padding: 0 !important;
		width:  100px;
		height: 100px;
	}
	@media (max-width: 767px) {
		width:  60px;
		height: 60px;
    	bottom: 10%;
	}

	.arrow{
	    position: absolute;
	    top: 50%;
	    right: 70px;
	    transform: rotate(-135deg);
	    width: 25px;
	    height: 25px;
	    border: 6px solid #000;
	    border-right: transparent;
	    border-top: transparent;
	    margin-top: -12px;

	    @media (max-width: 1820px) {
	    	left: auto;
	    	right: 46px;
	    }
		@media (max-width: 1420px) {
	    	left: auto;
	    	right: 40px;
		}
	    @media (max-width: 767px) {
			left: auto;
			right: 26px;
			width: 12px;
			height: 12px;
			border-width: 3px;
			margin-top: -6px;
	    }
	}

	.img-preview{
		display: block;
		max-width: 180px;

		@media (max-width: 1820px) {
			max-width: 130px;
		}

		@media (max-width: 1420px) {
			display: none;
		}
	}

	img{
		display: block;
	}

	&.carousel-prev{
		right: auto;
		left: 0;
		padding: 20px 20px 20px 155px;
	
		@media (max-width: 1820px) {
			padding: 5px 5px 5px 100px;
		}
		@media (max-width: 767px) {
			right: 0;
			left: auto;
			transform: translateY(-100%);
			margin-bottom: 10px;
		}

		.arrow{
			transform: rotate(45deg);
		    right: auto;
		    left: 70px;
		    @media (max-width: 1820px) {
		    	right: auto;
		    	left: 46px;
		    }
			@media (max-width: 1420px) {
		    	right: auto;
		    	left: 40px;
			}
		    @media (max-width: 767px) {
		    	right: auto;
		    	left: 26px;
		    }
		}
	}
}
img{
	max-width: 100%;
}

//Screen
.section-bg {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	.c-bg-carousel,
	.bg {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	.bg {
		background-color: #CCC;
		background-attachment: scroll;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		transform: translateZ(0);
		backface-visibility: hidden;
		// box-shadow: inset 0 0 20px 10px hsla(0,0%,100%,0.7);
		@media (max-width: 767px) {
			background-image: url('../img/background/sitebg_mob.jpg') !important;
		}
	}
}
.bg-animation {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	width: 40%;
	height: auto;
	transform: translate3d(-40.9%, -25.7%, 0);
	padding-top: 52%;

	.leaf {
		position: absolute;
		left: 0;
		top: 0;
		transform: translateZ(0) rotate(0deg) skew(0deg);
		transform-origin: 0 0;
		animation-name: leafanimation;
		animation-duration: 10s; 
		animation-timing-function: ease-in-out; 
		animation-delay: 0;
		animation-iteration-count: infinite;
		animation-play-state: running; 
		// animation-direction: alternate;
		will-change: transform;
		transition: 0s;

		&.leaf2 {
			left: 0;
			top: 0;
			animation-name: leafanimation1;
			transform: translateZ(0) rotate(0deg) skew(0deg);
			transform-origin: 0 0;
			animation-duration: 8s;
		}
	}
}
.screen {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	&.screen-privacy,
	&.screen-terms,
	&.screen-press-release,
	&.screen-home,
	&.screen-menu {
		.section-content {
			.section-header {
				justify-content: space-between;
			}
			.content {
				//padding-bottom: $headerYpad*2 + $menuiconHeight;
			}
		}
	}
	&.screen-press-release{
		&.is-ar{
			font-family: 'Effra', arial, sans-serif;
		}
	}
	&.screen-menu,
	&.screen-home{
		@media (max-width: 767px) {
			.section-content .content{
				display: flex;
	    		align-items: center;
				.overflow-div{
					width: 100%;
					display: block;
				    max-height: 100%;
				    overflow-x: hidden;
				    overflow-y: auto;
				    height: auto;
				    min-height: 0;
				}
			}
		}
	}
	&.screen-home{
		@media (max-width: 767px) and (max-height: 530px) {
			.section-content .content{
				padding-bottom: 80px;
			}
		}
		@media (max-width: 767px){
			.boxes-container{
				display: block;
			}
		}
	}
	&.screen-QAH{
		@media (max-width: 767px) {
			.section-content .content .overflow-div{
				display: flex;
			}
		}
	}
	&.screen-menu{
		@media (max-width: 767px) {
			.section-content .section-header{
				padding-bottom: 0;
			}
			.section-content .content{
				padding-bottom: 0px;
				padding-top: 70px;
				// align-items: flex-start;
				align-items: center;

				@media (max-height: 520px) {
					padding-top: 50px;
				}
			}
			@media (max-height: 520px) {
				.main-nav a{
					padding: 5px 0;
				}
				.subscribe-form{
					margin-top: 0;
				}
			}
		}
	}
	&.screen-QAH,
	&.screen-CF {
		.section-bg {
			.bg {
				// box-shadow: none;
			}
		}
		.section-content {
			.section-header {
				position: absolute;
				width: 100%;
				z-index: 2;
			}
		}
	}
}

@keyframes leafanimation {
    0% {
    	transform: translateZ(0) rotate(0deg) skew(0deg);
    }
    50% {
    	transform: translateZ(0) rotate(5deg) skew(5deg);
    }
    100% {
    	transform: translateZ(0) rotate(0deg) skew(0deg);
    }
}

@keyframes leafanimation1 {
    0% {
    	transform: translateZ(0) rotate(0deg) skew(0deg);
    }
    50% {
    	transform: translateZ(0) rotate(3deg) skew(2deg);
    }
    100% {
    	transform: translateZ(0) rotate(0deg) skew(0deg);
    }
}

.c-logo-abu-dhabi{
	position: absolute;
	bottom: 60px;
	right: $headerXpad;
	z-index: 5;
	max-width: 135px;

	@media (max-width: 1580px) {
		// right: $headerYpad;
	}
	@media (max-width: 1500px) {
		max-width: 125px;
	}
	@media (max-width: 1400px) {
	    right: auto;
	    left: 50%;
	    transform: translateX(-50%);
	    max-width: 120px;
	    bottom: 20px;
	}
	@media (max-width: 767px) {
	    max-width: 80px;
	    bottom: 5px;
	}
	img{
		display: block;
		width: 135px;
	}
}

.section-content {
	display: flex;
	position: relative;
	z-index: 2;
	flex-direction: column;
	height: 100%;
	text-align: center;
	justify-content: space-between;

	&.sec--only-header{
		height: auto;
	}

	.section-header {
		position: absolute;
		z-index: 3;
		width: 100%;
		left: 0;
		top: 0;
		display: flex;
		width: 100%;
		padding: $headerYpad $headerXpad;
		padding-bottom: 0;
		text-align: center;
		justify-content: flex-end;
		align-items: center;
		flex-shrink: 0;

		@media (max-width: 1580px) {
			padding-top: $headerYpad;
			padding-bottom: $headerYpad;
		}

	    @media (max-width: 1420px) {
	    	padding-right: 60px;
	    	padding-left: 60px;
	    }

		.switch-lang {
			font-size: 24px;
			font-weight: bold;
			display: block;
			font-family: $fontAR;
			position: relative;
			align-self: flex-start;
		    margin: auto 30px auto auto;
		    position: relative;
		    top: -4px;
			
			@media (min-width: 1025px) {
				&:after{
					content:'';
					display: block;
					height: 1px;
					background: #000;
					width: 0%;
					position: absolute;
					bottom: -5px;
					left: 50%;
					transform: translateX(-50%);
					transition: width 0.35s;
				}
				&:hover{
					&:after{
						width: 100%;
					}
				}
			}
		}
		.logo {
			display: block;
			position: absolute;
			left: $headerXpad;
			top: 50%;
			transform: translateY(-50%);

			@media (max-width: 1580px) {
				// left: $headerYpad;
			}
			
			&.logoQAH{
				margin-top: 10px;

				/* &:after{
					content: '';
					display: block;
					padding-top: 62%;
				} */
				img{
					/* position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%; */
					height: auto;
					max-width: none;
					max-height: none;
					width: 129px;

					@media (max-width: 767px){
						width: 100px;
						height: 70px;
					}
					@media (max-width: 359px) {
						width: 71px;
						height: 50px;
					}
				}
				@media (max-width: 767px) {
					margin-top: 5px;
				}
			}

			&.logoCF{
				img{
					height: auto;
					max-height: none;
					width: 185px;

					@media (max-width: 767px){
						width: 135px;
						height: 50px;
					}
					@media (max-width: 359px) {
						width: 105px;
						height: 39px;
					}
				}
			}

			img {
				// width: 100%;
				display: block;
			}
		}
		.logo-inline{
			margin: auto 0;
			img{
				@media screen and (max-width: 1800px){
					max-height: 20px;
				}
				@media screen and (max-width: 14000px){
					max-height: 18px;
				}
				@media screen and (max-width: 767px){
					max-height: 16px;
				}
				@media screen and (max-width: 339px){
					max-height: 15px;
				}
			}
		}
		&.header--white{
			.cta-menu {
				// background: url(../img/icons/bars-white.png) center center no-repeat scroll;
				i{
					background: #fff;
				}
			}
			.switch-lang {
				color: $white;
			}
		}
	}
	.content {
		height: 100%;
	    flex-shrink: 1;
	    padding: $headerYpad*2 + $menuiconHeight $headerXpad;
	    position: relative;
	    overflow: auto;

	    @media (max-width: 1420px) {
	    	padding-right: 60px;
	    	padding-left: 60px;
	    }
	    .overflow-div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 100%;
			@media (max-width: 767px) {
				display: block;
				height: 100%;
				overflow-x: hidden;
				overflow-y: auto;
			}
		}

		&.content-sty-1{
			text-align: left;
			padding-bottom: 50px;
			@media (max-width: 1400px) {
				padding-bottom: 100px;
				.o-container{
					height: 100%;
					overflow: auto;
					padding-right: 10px;
				}
			}
			@media (max-width: 767px) {
				padding-bottom: 43px;
			}

			.points{
				display: flex;
				.index{
					padding-right: 18px;
				}
			}

			h1{
				font-weight: bold;
				font-size: 36px;
				line-height: 1.2em;
				margin: 0 0 40px;
				@media (max-width: 1620px){
					font-size: 30px;
				}
				@media (max-width: 767px) {
				    font-size: 26px;
				    margin-bottom: 15px;
				}
			}
			h2{
				font-weight: bold;
				font-size: 24px;
				line-height: 1.2em;
				margin: 0 0 20px;
				@media (max-width: 1620px){
					font-size: 20px;
				}
				@media (max-width: 767px){
					font-size: 17px;
					margin-bottom: 15px;
					br{
						display: none;
					}
				}
			}
			.heading-detail,
			p{
				font-size: 18px;
				line-height: 1.5em;
				margin: 0 0 20px;
				@media (max-width: 1620px){
					font-size: 15px;
				}
				@media (max-width: 1400px) {
					margin: 0 0 15px;
				}
				@media (max-width: 767px) {
					margin: 0 0 10px;
					font-size: 12px;
				}
			}
			img{
				float: left;
				margin: 0 40px 40px 0;

				@media (max-width: 767px) {
					margin: 20px auto;
					float: none;
					width: 100%;
					max-width: 400px;
					display: block;
				}
			}
			.c-logo-abu-dhabi{
				img{
					margin: 0;
				}
			}
		}
	}
}

.cta-menu {
	display: block;
	width: 42px;
	height: 24px;
	color: rgba(0,0,0,0);
	overflow: hidden;
	text-indent: -9999px;
	position: relative;
	// background: url(../img/icons/bars.png) center center no-repeat scroll;

	.txt{
		display: none;
	}
	i{
		display: block;
		position: absolute;
		top: 0;
		height: 6px;
		width: 100%;
		left: 0;
		background: #000;
		transition: all .2s;

		&:nth-child(2){
			bottom: 0;
			top: auto;
		}
	}

	&.white {
		i{
			background: #fff;
		}
	}

	@media (min-width: 1025px) {
		&:hover{
			i{
				top: 2px;

				&:nth-child(2){
					top: auto;
					bottom: 2px;
				}
			}
		}
	}


	&.menu--close{
		width:  46px;
		height: 46px;
		transform: rotate(45deg);
		transition: all .35s;

		i{
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			&:nth-child(2){
				top: 0;
				bottom: auto;
				left: 50%;
				transform: translateX(-50%);
				width: 6px;
				height: 100%;
			}
		}
		
		@media (min-width: 1025px) {
			&:hover{
				transform: rotate(135deg);
				i{
					
				}
			}
		}
	}

	@media (max-width: 1800px){
		width: 35px;
		height: 20px;
		i{
			height: 4px;
			&:nth-child(2){
			
			}
		}
			
		&.menu--close{
			width: 35px;
			height: 35px;
			i{
				height: 4px;
				&:nth-child(2){
					width: 4px;
					height: 100%;
				}
			}
		}

		&.menu--close{
			/* width: 40px;
			height: 40px; */
		}
	}
	@media (max-width: 1620px){
	    /* width: 32px;
	    height: 24px; */

		&.menu--close{
			width: 34px;
			height: 34px;
		}
	}
	@media (min-width: 767px) {
		width: 33px; 
		height: 18px;
		background-size: 100%;

		i{
			width: 100%;
			height: 4px;

			&:nth-child(2){
			}
		}

		&.menu--close{
			width: 30px;
			height: 30px;

			i{
				width: 100%;
				height: 3px;
				&:nth-child(2){
					height: 100%;
					width: 3px;
				}
			}
		}
	}
	@media (min-width: 359px) {
		width: 33px; 
		height: 20px;
		background-size: contain;
	}
}
.header--white {
	.cta-menu {
		@media (min-width: 767px) {
			background-size: 100% 100%;
		}
	}
}

@media (max-width: 767px) {
	.screen-home .section-content .content .overflow-div{
		display: flex;
	}
}
.o-container{
	max-width: 980px;
	margin: 0 auto;
	position: relative;

	@media (max-width: 1820px) {
		max-width: 880px;
	}
}
.paddedX {
	max-width: 560px;
	margin: 0 auto;
}
.section-dragable {
	padding: 0;
	width: 100%;
	position: fixed;
	left: 50%;
	bottom: $headerYpad;
	transform: translateX(-50%);
	z-index: 3;
	text-align: center;
	// overflow: hidden;
	will-change: margin, opacity;

	@media (max-width: 1400px) {
		bottom: 100px;
	}
}
.boxes-container {
	display: flex;
	width: 100%;
	padding-left: 55px;
	padding-right: 55px;
	margin: 0 auto;
	max-width: 700px;

	@media (max-width: 1820px) {
		max-width: 670px;
	}
	.row {
		margin-left: -55px;
		margin-right: -55px;
		min-height: 1px;
		display: flex;
		@media (max-width: 1820px) {
			margin-left:  -40px;
			margin-right: -40px;
		}
		.grid {
			padding: 55px 30px;
			width: 50%;
			@media (max-width: 1820px) {
				padding: 40px 30px;
			}
		}
	}
}
.content-box {
	width: 100%;
	height: 100%;
	position: relative;
	padding: 35px 35px;
	background-color: $white;
	text-align: left;
	box-sizing: border-box;
		// box-shadow: 0 30px 50px -5px rgba(85,85,85,0.4);
	
	&[data-topage]{
		cursor: pointer;
	}

    @media (max-width: 1024px) {
		// box-shadow: 0 30px 50px -5px rgba(85,85,85,0.4);
    }

	&:after{
	    content: "";
	    display: block;
	    	display: none;
	    background: rgba(85,85,85,.6);
	    width: 100%;
	    height: 100%;
	    transform: scale(1.1) translateY(20px);
	    filter: blur(15px);
	    position: absolute;
	    top: 0;
	    left: 0;
	    @media (max-width: 1024px) {
	    	display: none;
	    }
	}
	.box-logo {
		margin-bottom: 10px;
		max-width: 185px;
	}
	p {
		text-align: left;
		margin-top: 30px;
		margin-bottom: 80px;
	}
}
.dragable-cover {
	max-width: 370px;
	margin: 0 auto;
	background-color: rgba(255,255,255,0.5);
	position: relative;
	padding: 0 10px;
	&:after,
	&:before {
		content: "";
		position: absolute;
		border-color: rgba(0,0,0,0);
		border-width: 19px;
		border-style: solid;
		border-right-color: rgba(255,255,255,0.5);
		left: -38px;
		top: 0;
		width: 0;
		height: 0; 
	}
	&:after {
		right: -38px;
		left: auto;
		border-right-color: rgba(255,255,255,0);
		border-left-color: rgba(255,255,255,0.5);

	}
}
.dragable-slider {
	padding: 15px 0;
	border: 0 !important;
	width: 100%;
	height: auto;
	border-radius: 0;
	position: relative;
	background-color: transparent;
	cursor: pointer;

	.checkpoints {
		display: flex;
		position: relative;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		.point {
			display: block;
			width:  8px;
			height: 8px;
			border: 2px solid $black;
			background: #d6d6d6;

			&:first-child {
				margin-left: -4px;
			}
			&:last-child {
				margin-right: -4px;
			}
		}
	}
	.ui-slider-handle {
		width:  36px;
		height: 36px;
		border: 0;
		border-radius: 0;
		background-color: transparent;
		top: 2px;
		margin-left: -18px;
		cursor: pointer;
		// transition: all 0.2s linear 0s;

		&.ui-state-hover {
			transition: none;
		}
		&:after,
		&:before {
			content: "";
			position: absolute;
			border: 5px solid transparent;
			border-top-color: #000;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: #000;
			left: 0;
			top: 6px;
		    width: 12px;
		    height: 12px;
			transform: rotate(-45deg);
			transform-origin: center;
		}
		&:after {
			right: 0;
			left: auto;
			transform: rotate(135deg);
		}
	}
	.line {
		width: calc(50% - 18px);
		height: 2px;
		background-color: $black;
		position: absolute;
		top: 50%;
		margin-top: -1px;
		&.line--left {
			left: 2px;
		}
		&.line--right {
			right: 2px;
			left: auto;
		}
	}
}
/* .c-social-1{
	display: block;
	clear: both;
	margin-top: 20px;
	li{
		display: inline-block;
		margin-right: 20px;
		&:last-child{
			margin-right: 0;
		}

		a{
			.icon{
				display: block;
				width: 65px;
				height: 50px;
				background: rgba(0,0,0,0.75) url('../img/icons/social-fb.png') no-repeat center center;
				transition: all .35s;

				@media (max-width: 767px){
					width: 55px;
					height: 40px;
					background-size: 9px auto;
				}

				&.icon-twitter{
					background-image: url('../img/icons/social-twitter.png');
					@media (max-width: 767px){
						background-size: 16px auto;
					}
				}
			}
			.txt{
				display: none;
			}

			&:hover{
				.icon{
					background-color: rgba(0,0,0,1);
				}
			}
		}
	}
} */
/* .c-social{
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
	position: absolute;
    z-index: 4;
    padding: 0;
    right: $headerXpad;
    align-items: center;
    width: 44px;
    top: $headerYpad*2 + $menuiconHeight;
    bottom: $headerYpad*2 + $menuiconHeight;
    > div {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		justify-content: center;
    }
	&:before, &:after{
    	content: '';
    	display: block;
    	height: 100%;
    	background: rgba(255,255,255,.25);
    	width: 1px;
    }
	&:before{
		margin-bottom: 20px;
	}
	&:after{
		margin-top: 20px;
	}
	a{
		display: flex;
		width:  28px;
		height: 28px;
		position: relative;
		margin: 15px 0;
		text-align: center;
		background-color: rgba(0,0,0,0);
		color: $black;
		padding: 0;
		flex-direction: column;
		justify-content: center;
		will-change: background-color;
		transition: all 0.35s ease 0s;
		opacity: .5;
		.scicons {
			max-width: 100%;
			max-height: 100%;
			display: block;
			path{
				fill: rgba(255,255,255,0.75);
			}
		}
		span{
			display: none;
		}
		&:hover {
			opacity: 1;
		}
	}
} */
.slide-label {
	text-align: center;
	color: rgba(0,0,0,0.9);
	text-transform: capitalize;
	margin: 15px 0 0 0;
	font-size: 15px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);

	@media (max-width: 1400px) {
		display: none;
	}

	.ar{
		display: none;
	}
}
.siteNavigation {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: $headerYpad/2;
	z-index: 10;
	a {
		text-align: center;
		color: rgba(0,0,0,0.5);
		text-transform: uppercase;
		margin: 0 15px;
		font-size: 16px;	
		padding: 10px;
		font-weight: bold;
		&:hover {
			color: rgba(0,0,0,1);
			transition: all 0.35s ease 0s;
		}
	}
}
.main-nav {
	max-width: 475px;
	width: 100%;
	margin: 0 auto;
	div {
		&:first-child {
			a {
				margin-top: 0;
			}
		}
		&:last-child {
			a {
				margin-bottom: 20px;
				@media (max-width: 1024px){
					margin-bottom: 15px;
				}
				@media (max-width: 767px){
					margin-bottom: 10px;
				}
			}
		}
	}
	a {
		display: inline-block;
		clear: both;
		font-size: 36px;
		margin: 0 auto;
		text-align: center;
		padding: 15px 10px;
		color: $black;
		font-weight: 900;
		position: relative;
		transition: opacity 0.35s;
		text-transform: uppercase;

		@media (max-width: 1200px){
			font-size: 28px;
		}

		/* &:after,
		&:before {
			position: absolute;
			content: "";
			width: 20px;
			height: 20px;
			border: 2px solid $black;
			left: 0;
			top: 15px;
			transform: scale(0);
			transition: all 0.35s ease 0s;
		}
		&:before {
			border-right-color: transparent; 
			border-bottom-color: transparent; 
			transform-origin: left top
		}
		&:after {
			border-left-color: transparent; 
			border-top-color: transparent; 
			transform-origin: bottom right;
			left: auto;
			top: auto;
			bottom: 15px;
			right: 0;
		}
		&:hover {
			&:before,
			&:after {
				transform: scale(1);
			}
		} */

		&:hover{
			opacity: 0.6;
		}
	}
}
input, textarea, button{
	border-radius: 0;
	-webkit-appearance: none;
}
.subscribe-form {
	max-width: 475px;
	width: 100%;
	margin: 30px auto 0;
	display: block;
	position: relative;
	padding: 25px;
	background-color: $white;
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);

	.button{
		text-align: right;
		@media (max-width: 767px){
			margin-top: 30px;
		}
	}

	#recaptcha{
		@media (max-width: 767px){
			display: none;
		}
	}

	> h3 {
		font-size: 24px;
		font-weight: bold;
		text-align: center;
		margin: 0 0 30px 0;
	}

	label{
		cursor: pointer;
	}

	form {
		padding: 0 40px;
	}

	input {
		background: transparent;
		border: 0;
		width: 100%;
		display: block;
		padding: 10px 0px;
		line-height: 1;
		color: $black;
		font-size: 15px;
		margin-bottom: 25px;
		border-bottom: 1px solid #b9b9b9;

		&.error{
			border-bottom-color: #f00;
		}
	}

	.form-submit-msg{
		.response{
			margin: -10px 0 20px;
			display: none !important;

			@media (max-width: 767px){
				margin: 0px 0 20px;
			}

			&.response--custom{
				display: block !important;
			}

			&[style="display: none;"],
			&[style="display:none;"],
			&[style="display: none"],
			&[style="display:none"]{
				+ .response--custom{
					display: none !important;
				}
			}
			&.response--success{
			    // background: #5a2;
			    color: #000;
			    padding: 2px;
			}
			&.response--error{
			    // background: #f44;
				/* color: rgba(0,0,0,.7);
				padding: 2px;
				font-size: 14px;
				@media (max-width: 1620px){
					font-size: 12px;
				} */
			}
			a{
				display: block;
				color: #fff;
				text-decoration: underline;
			}
		}
	}
	.switch{
		display: flex;
		align-items: center;
	}
	.onoffswitch {
	    position: relative;
	    width: auto;
	    display: inline-block;

	    &.error{
	    	.onoffswitch-checkbox{
	    		border-color: #f00;
	    	}
	    }
		.onoffswitch-checkbox {
		    display: block;
		    width: 30px;
		    height: 30px;
		    opacity: 1;
		    border: 1px solid #bfbfbf;
		    visibility: visible;
		    margin: 0;
		    padding: 0;
		    cursor: pointer;
		    margin-right: 15px;

		    @media (max-width: 767px){
		    	width: 20px;
		    	height: 20px;
		    	margin-right: 8px;
		    }

		    &:checked:after{
				content: "";
				display: block;
				position: absolute;
				top: 2px;
				left: 10px;
				width: 6px;
				height: 16px;
				border: 5px solid #000;
				border-top: 0;
				border-left: 0;
		    	transform: rotateZ(45deg);

		    	@media (max-width: 767px){
					border-width: 2px;
					width: 4px;
					height: 10px;
					top: 3px;
					left: 7px;
		    	}
		    }
		}
		.onoffswitch-label {
		    display: block;
		    overflow: hidden;
		    cursor: pointer;
		    background-color: rgba(0,0,0,0.5);
		    transition: all 0.3s ease-in 0s; 
		}
		.onoffswitch-inner{
		    display: block;
		    width: 200%;
		    margin-left: -100%;
		    transition: margin 0.3s ease-in 0s;
			&:before,
			&:after {
			    display: block;
			    float: left;
			    width: 50%;
			    height: $animatedButtonHeight;
			    padding: 0;
			    line-height: $animatedButtonHeight;
			    font-size: 10px;
			    color: white;
			    box-sizing: border-box;
			    content: "";
			    background-size: auto;
			    background-attachment: scroll;
			    background-repeat: no-repeat;
			}
			&:before {
			    background-image: url(../img/icons/on.png);
			    background-position: 16px center;
			}
			&:after {
			    background-image: url(../img/icons/off.png);
			    background-position: 52px center;
			}
		}
		.onoffswitch-switch {
		    display: block;
		    width: 24px;
		    margin: 7px;
		    background: #FFFFFF;
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    right: 50px;
		    transition: all 0.3s ease-in 0s; 
		}
		.onoffswitch-checkbox{
			&:checked {
				+ .onoffswitch-label {
					background-color: rgba(0,0,0,0.75);
					.onoffswitch-inner {
					    margin-left: 0;
					}
					.onoffswitch-switch {
					    right: 0px; 
					}
				}
			}
		}
	}
	.switchbox-flex {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		font-size: 15px;
		color: #4c4c4c;
		margin: 40px 0 30px;

		.switch {
			&:first-child {
				text-align: left;
			}
			&:last-child {
				// text-align: right;
			}
		}
	}

}


.animated-button {
	display: block;
	position: relative;
	width: 150px;
	height: $animatedButtonHeight;
	margin: 0 auto;
	.btn-text {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		display: block;
		width: 150px;
		opacity: 1;
		line-height: $animatedButtonHeight;
		height: $animatedButtonHeight;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 900;
		color: $black;
		transition: all 0.25s ease 0s;
		z-index: 1
	}

	&:after,
	&:before {
		width: 14px;
		height: 28px;
		position: absolute;
		border: 5px solid $black;
		content: "";
		top: 0;
		transition: all 0.25s ease 0s;
	}
	&:before {
		left: 0;
		border-right: 0;
	}
	&:after {
		right: 0;
		border-left: 0;
	}
	&.submitNow {
		pointer-events: none;
		&:before {
			left: 50%;
			margin-left: -19px;
		}
		&:after {
			right: 50%;
			margin-right: -19px;
		}
		.btn-text {
			opacity: 0;
		}
	}
	.submiting {
		width: $animatedButtonWidth;
		height: $animatedButtonHeight;
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
		border: 5px solid black;
		transition: all 0.05s ease 0s;
		background: #fff;
		opacity: 0;

		&:after,
		&:before {
			width:  $animatedButtonWidth;
			height: $animatedButtonHeight;
			position: absolute;
			border: 0;
			content: "";
			animation-duration: 2s; 
			animation-timing-function: ease; 
			animation-delay: 0s;
			animation-iteration-count: infinite;
			animation-play-state: paused; 
			animation-fill-mode: forwards;
			animation-direction: normal;
			visibility: hidden;
			box-sizing: border-box;
			// padding-top: 5px;
			transform: translateZ(0)
		}
		&:before {
			left:  -5px;
			bottom: -5px;
			border-left: 5px solid white;
			border-top: 5px solid white;
			animation-name: topleft;
		}
		&:after {
			right: -5px;
			top: -5px;
			border-right: 5px solid white;
			border-bottom: 5px solid white;
			animation-name: bottomright;
		}
		&.submit--black {
			z-index: 4;
			background-color: transparent;
			border-color: transparent;
			&:after,
			&:before {
				//animation-delay: 1s;
			}
			&:before {
				left:   -5px;
				bottom: -5px;
				right: auto;
				top:   auto;
				border-left: 5px solid black;
				border-top: 5px solid black;
				animation-name: topleft1;
			}
			&:after {
				right: -5px;
				top:   -5px;
				bottom: auto;
				left:   auto;
				border-right: 5px solid black;
				border-bottom: 5px solid black;
				animation-name: bottomright1;
			}
		}
	}
	.state-approved {
		width: 38px;
	    height: 28px;
	    position: absolute;
	    z-index: 5;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    display: block;
	    opacity: 1;
		.checkmark {
			width: 20px;
			height: 100%;
			.checkmark__check {
				transform-origin: 50% 50%;
				stroke-dasharray: 48;
				stroke-dashoffset: 48;
				stroke: #000;
				stroke-width: 6px;
			}
		}
	}
	.state-error {
		width: 38px;
	    height: 28px;
	    position: absolute;
	    z-index: 5;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    display: block;
	    opacity: 1;
		.checkmark {
			width: 20px;
			height: 100%;
			.checkmark__check {
				transform-origin: 50% 50%;
				stroke-dasharray: 50;
				stroke-dashoffset: 50;
				stroke: #000;
				stroke-width: 6px;
			}
		}
	}

	.c-box{
		opacity: 0;
		.side{
			animation-play-state: paused;
		}
	}

	&.isloading {
		&:before,
		&:after {
			opacity: 0;
			transition: 0s;
		}
		.submiting {
			opacity: 1;
			&:after,
			&:before {
				animation-play-state: running; 
			}
		}
		.c-box{
			opacity: 1;
			.side{
				animation-play-state: running;
			}
		}
		&.submit-error,
		&.submit-success {
			&:before,
			&:after {
				opacity: 1;
				transition: none
			}
			.submiting {
				opacity: 0;
				transition: none;
				&:after,
				&:before {
					animation-play-state: paused; 
				}
			}
			.c-box{
				.side{
					animation-play-state: paused;
				}
			}
		}
		&.submit-success {
			.state-approved {
				.checkmark {
					.checkmark__check {
						stroke-dashoffset: 0;
						transition: 1s ease 0s;
					}
				}
			}
		}
		&.submit-error {
			.state-error {
				.checkmark {
					.checkmark__check {
						stroke-dashoffset: 0;
						transition: 1s ease 0s;
					}
				}
			}
		}
	}
}
@keyframes topleft {
  0% {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  12.5% {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  25% {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  37.4% {
	visibility: visible;
  }
  37.5% {
    width: 0;
    height: $animatedButtonHeight;
	visibility: visible;
  }
  50% {
    width: $animatedButtonWidth;
    height: $animatedButtonHeight;
	visibility: visible;
  }
  100% {
    width: $animatedButtonWidth;
    height: $animatedButtonHeight;
	visibility: visible;
  }
}
@keyframes bottomright {
  0% {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  12.5% {
    height: $animatedButtonHeight;
    width: 0;
    visibility: visible;
  }
  25% {
    height: $animatedButtonHeight;
    width: $animatedButtonWidth;
    visibility: visible;
  }
  37.5% {
    height: $animatedButtonHeight;
    width: $animatedButtonWidth;
    visibility: visible;
  }
  50% {
    height: $animatedButtonHeight;
    width: $animatedButtonWidth;
    visibility: visible;
  }
  100% {
    height: $animatedButtonHeight;
    width: $animatedButtonWidth;
    visibility: visible;
  }
}
@keyframes bottomright1 {
  0% {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  49.9% {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  50% {
    height: 0;
    width: 0;
    visibility: visible;
  }
  62.5% {
    height: $animatedButtonHeight;
    width: 0;
    visibility: visible;
  }
  75% {
    height: $animatedButtonHeight;
    width: $animatedButtonWidth;
    visibility: visible;
  }
}
@keyframes topleft1 {
  0% {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  74.9% {
    height: 0;
    width: 0;
	visibility: hidden;
  }
  75% {
    height: 0;
    width: 0;
	visibility: visible;
  }
  87.5% {
    width: 0;
    height: $animatedButtonHeight;
	visibility: visible;
  }
  100% {
    height: $animatedButtonHeight;
    width: $animatedButtonWidth;
    visibility: visible;
  }
}
/* .play-btn {
	width: 154px;
	height: 154px;
	a {
		display: block;
		opacity: 0.4;
		transition: all .35s ease 0s;
		img {
			width: 100%;
			display: block;
		}
		&:hover {
			opacity: 1;
		}
	}
} */

.alert-warnning {
    position: fixed;
    z-index: 9999;
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 700;
    color: #fff;
    background: #555555;
    text-align: center;
    padding: 60px 100px;
    overflow: hidden;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
/* .ply-desktop {
	display: block !important;
}
.ply-mobile {
	display: none !important;
} */

/* .home--no-box{
	.content-box{
		background: transparent;
		@media (max-width: 1024px) {
			background: #fff;
		}
		> *{
			opacity: 0;

			@media (max-width: 1024px) {
				opacity: 1;
			}
		}
	}
} */
/* .home--only-box{
		display: none;
	pointer-events: none;
	.paddedX{
		opacity: 0;
	}
	.content-box{
		// box-shadow: none;

		&:after{
			display: none;
		}
	}
	.section-content:after{
		display: none !important;
	}

	@media (max-width: 1024px) {
		display: none;
	}
} */


.is-ar{
	.screen-press-release .o-container{
		text-align: left;
		h1, p{
			direction: ltr;
		}
	}
	.carousel-nav.carousel-prev,
	.carousel-nav.carousel-next{
		@media (max-width: 767px){
			/* left: 0;
			right: auto; */
		}
	}
	.c-logo-abu-dhabi{
		/* right: auto;
		left: 80px; */
		@media (max-width: 1400px){
			/* right: auto;
			left: 50%; */
		}
	}
	.subscribe-form .button{
		text-align: left;
	}
	.screen-menu .section-content .content .overflow-div{
		direction: rtl;
		text-align: right;
	}
	.screen-CF .section-bg, .screen-QAH .section-bg{
		right: 0;
		left: auto;
	}
	.screen-CF .section-content-1, .screen-QAH .section-content-1{
		text-align: right;
		left: 0;
		right: auto;
	}
	.section-content .section-header .logo{
		/* left: auto;
		right: 80px; */
		@media (max-width: 767px){
			/* left: auto;
			right: 20px; */
		}
	}
	.section-content .section-header .switch-lang{
		/* margin-right: auto;
		margin-left: 30px; */
	}
	.section-content .section-header{
		// direction: rtl;
	}
	.subscribe-form .form-submit-msg .response{
		text-align: right;
	}
	@media screen and (max-width: 1800px){
	.section-content .section-header .logo-inline img {
	    max-height: 23px;
	}
	}
	@media screen and (max-width: 14000px){
	.section-content .section-header .logo-inline img {
	    max-height: 23px;
	}
	}
	@media screen and (max-width: 767px){
	.section-content .section-header .logo-inline img {
	    max-height: 20px;
	}
	}
	@media screen and (max-width: 339px){
	.section-content .section-header .logo-inline img {
	    max-height: 19px;
	}
	}
	&.screen-CF .section-content-1 h1, &.screen-QAH .section-content-1 h1{
		letter-spacing: 0px;
	}
	.subscribe-form .onoffswitch .onoffswitch-checkbox:checked:after{
		right: 10px;
		left: auto;
		@media (max-width: 767px){
			left: auto;
			right: 7px;
		}
	}
	.subscribe-form .onoffswitch .onoffswitch-checkbox{
		margin-right: 0;
		margin-left: 15px;
		@media (max-width: 767px){
			margin-right: 0;
			margin-left: 8px;
		}
	}
	.content-box {
		text-align: right;
	}
	.boxes-container .row{
    	direction: ltr;
	}
	.subscribe-form .switchbox-flex .switch:last-child {
	    text-align: left;
	}
	.subscribe-form .switchbox-flex .switch:first-child {
	    text-align: right;
	}
	.section-content .content.content-sty-1{
		text-align: right;
	}
	.section-content .content.content-sty-1 img{
		@media (min-width: 768px){
			float: right;
			margin: 0 0 40px 40px;
		}
	}
	font-family: 'FrutigerLTArabic';
	.subscribe-form input {
		font-family: 'FrutigerLTArabic';
	}
	.section-content .section-header .switch-lang {
		font-family: 'Effra', arial, sans-serif;
		top: 0;
	}

	.subscribe-form .onoffswitch .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner{
		margin-right: -100%;
		margin-left: 0;
	}
	.content-box p {
		text-align: right;
	}
	.boxes-container,
	.subscribe-form {
		direction: rtl
	}
	.main-nav a {
		padding: 8px 10px;
		font-size: 30px;
		@media (max-width: 1200px){
			font-size: 26px;
		}
		@media (max-width: 767px){
			    font-size: 20px;
		}
	}
	// .main-nav a:after{
	// 	bottom: 5px;
	// }
	// .main-nav a:before {
	// 	top: 5px;
	// }
	.subscribe-form>h3 {
		// font-size: 20px;
	    // margin: 0 0 25px;
	}
	.c-main-mdoule h1{
		letter-spacing: 0px;
	}
	.c-main-mdoule .left{
		order: 1;
		text-align: right;
		@media (max-width: 992px){
			// text-align: center;
		}
	}
	.c-main-mdoule .left{
		padding-left: 50px;
		padding-right: 0;
		@media (max-width: 992px){
			padding-left: 0;
		}
	}
}

@media (min-width: 1025px) and (max-height: 1040px) {
	.section-content {
		.section-header {
			padding-top: $headerYpad1;	
			padding-bottom: $headerYpad1;	
		}
		.content {
		    padding-top: $headerYpad1*2 + $menuiconHeight;
		    padding-bottom: $headerYpad1*2 + $menuiconHeight;
		}
	}
	.section-dragable {
		// bottom: $headerYpad1
	}
	.boxes-container {
		.row {
			.grid{
				padding-top: 40px;
				padding-bottom: 40px;
			     padding-right: 20px;
			    padding-left: 20px;
			}
		}
	}
	.c-social{
	    top: $headerYpad1*2 + $menuiconHeight;
	    bottom: $headerYpad1*2 + $menuiconHeight;
	}
	.subscribe-form {
		// padding: 15px 25px;
	}
	.main-nav a {
		padding: 5px 10px;
		&:before {
			top: 5px;
		}
		&:after {
			top: auto;
			bottom: 5px;
		}
	}
}
@media (min-width: 1025px) and (max-height: 960px) {
	.section-content {
		.section-header {
			padding-top: $headerYpad2;	
			padding-bottom: $headerYpad2;	
		}
		.content {
		    padding-top: $headerYpad2*2 + $menuiconHeight;
		    padding-bottom: $headerYpad2*2 + $menuiconHeight;
		}
	}
	.section-dragable {
		// bottom: $headerYpad2
	}
	.content-box {
		p {
			margin-bottom: 30px;
		}
	}
	.c-social{
	    top: $headerYpad2*2 + $menuiconHeight;
	    bottom: $headerYpad2*2 + $menuiconHeight;
	}
	.subscribe-form {
		margin-top: 10px;
		h3 {
			margin-bottom: 25px;
		}
		.switchbox-flex {
			margin: 30px 0 20px;
		}
	}
}
@media (min-width: 1025px) and (max-height: 880px) {
	.section-content {
		.section-header {
			padding-top: $headerYpad3;	
			padding-bottom: $headerYpad3;
			.logo {
				img{
					// width: auto;
					max-height: 90px;
				}
			}
		}
		.content {
		    padding-top: $headerYpad3*2 + $menuiconHeight;
		    padding-bottom: $headerYpad3*2 + $menuiconHeight;
		}
	}
	.content-box {
		padding: 30px 50px;
		p {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
	.c-social{
	    top: $headerYpad3*2 + $menuiconHeight;
	    bottom: $headerYpad3*2 + $menuiconHeight;
	}
	.main-nav a {
		margin: 0;
	}
}
@media (min-width: 1025px) and (max-height: 800px) {
	.section-content {
		.section-header {
			padding-top: $headerYpad4;	
			padding-bottom: $headerYpad4;
		}
		.content {
		    padding-top: $headerYpad4*2 + $menuiconHeight;
		    padding-bottom: $headerYpad4*2 + $menuiconHeight;
		}
	}
	.boxes-container {
		.row {
			.grid{
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
	}
	.content-box {
		padding: 25px 40px;
		.box-logo {
			max-height: 90px;
		}
		p {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
	.c-social{
	    top: $headerYpad4*2 + $menuiconHeight;
	    bottom: $headerYpad4*2 + $menuiconHeight;
	}
}
@media (min-width: 1025px) and (max-height: 760px) {
	.boxes-container {
	    padding-left: 40px;
	    padding-right: 40px;
		.row {
			margin-left: -20px;
			margin-right: -20px;
			.grid{
				padding: 20px;
			}
		}
	}
	.content-box {
		padding: 25px 40px;
		.box-logo {
			max-height: 80px;
		}
		p {
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}
	.main-nav a {
		font-size: 30px;
	}
	.subscribe-form {
		>h3 {
			font-size: 24px;
		}
		input {
			margin-bottom: 18px;
			font-size: 16px;
		}
		.switchbox-flex {
			margin: 20px 0;
		}
	}
}
@media (min-width: 1025px) and (max-height: 645px) {
	body, html {
		font-size: 16px;
	}
	.screen-menu,
	.screen-home {
		.section-header {
			background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		}
		.section-content {
			position: relative;
			&:after {
				content: "";
				width: 100%;
				height: 100px;
				position: absolute;
				bottom: 0;
				z-index: 2;
				left: 0;
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.9) 50%);
			}
		}
	}
	.section-dragable {
		bottom: 90px;
	}
	.dragable-cover {
		background: transparent;
		&:after,
		&:before {
			border-color: transparent;
		}
	}
	.slide-label {
		display: none;
	}
	.content-box .box-logo {
		max-height: 70px;
	}
	.siteNavigation {
		bottom: 20px;
	}
	.main-nav a {
		margin-bottom: 10px;
	}
	.subscribe-form {
		margin-top: 30px;
		input {
			margin-bottom: 30px;
		}
		.switchbox-flex {
			margin: 40px 0 30px;
		}
	}
}

@media (max-height: 1800px) {
	.section-content .section-header .switch-lang{
		font-size: 22px;
	}
	body, html{
		font-size: 15px;
	}
	.content-box .box-logo {
	    max-height: 80px;
	}
	.is-ar .main-nav a,
	.main-nav a{
		// font-size: 24px;
	}
	.subscribe-form>h3{
		// font-size: 21px;
	}
	.subscribe-form input{
		// font-size: 16px;
	}
	.siteNavigation a{
		font-size: 14px;
	}
	.animated-button .btn-text{
		font-size: 16px;
	}
	.play-btn {
	    width: 130px;
	    height: 130px;
	}
}
@media (max-width: 1620px) {
	.section-content .section-header .switch-lang {
	    font-size: 20px;
	}
	body, html {
	    font-size: 15px;
	}
	.paddedX {
    	max-width: 620px;
	}
	.play-btn {
	    width: 110px;
	    height: 110px;
	}
	.section-content .section-header .logo{
		max-width: 160px;
	}
	.content-box .box-logo{
		max-height: 60px;
	}
	.content-box p {
	    margin-top: 10px;
	    margin-bottom: 10px;
	}
	.content-box {
	    padding: 40px 30px;
	}
	.is-ar .main-nav a,
	.main-nav a {
	    // font-size: 20px;
	}
	.subscribe-form>h3 {
	    // font-size: 18px;
	}
	.subscribe-form input{
		font-size: 14px;
	}
	.subscribe-form .switchbox-flex{
		font-size: 14px;
	}
	.siteNavigation a{
		font-size: 14px;
	}
}

@media (max-width: 1024px) {
	/* .ply-desktop {
		display: none !important;
	}
	.ply-mobile {
		display: block !important;
	} */
	@media (max-height: 1024px){
		.section-content {
			.section-header {
				padding: $headerYpad4 $headerXpad4;	
				.logo {
					left: $headerXpad4;
					img {
						max-width: 140px;
						max-height: 80px;
						// width: auto;
					}
				}
			}
			.content {
			    padding: $headerYpad4*2 + $menuiconHeight $headerXpad4;
			}
		}
		.play-btn {
			width: 100px;
			height: 100px;
			a {

			}
		}
		.boxes-container {
			.row {
				.grid{
					padding-top: 30px;
					padding-bottom: 30px;
				}
			}
		}
		.content-box {
			padding: 25px 40px;
			.box-logo {
				max-height: 90px;
			}
			p {
				margin-top: 20px;
				margin-bottom: 20px;
			}
		}
		.c-social{
		    top: $headerYpad4*2 + $menuiconHeight;
		    bottom: $headerYpad4*2 + $menuiconHeight;
		}
		.boxes-container {
			padding-left: 20px;
			padding-right: 20px;
			.row {
				margin-left: -20px;
				margin-right: -20px;
				.grid{
					padding: 20px;
				}
			}
		}
		.content-box {
			padding:40px 30px;
			.box-logo {
				max-height: 70px;
			}
			p {
				margin-top: 20px;
				margin-bottom: 10px;
			}
		}
		.main-nav a {
			// font-size: 24px;
			padding: 10px 10px;
			margin: 0;
		}
		.subscribe-form {
			margin-top: 20px;
			padding: 20px 25px;
			>h3 {
				// font-size: 20px;
			}
			input {
				margin-bottom: 18px;
				// font-size: 16px;
			}
			.switchbox-flex {
				margin: 20px 0;
			}
		}
		.c-social {
			right: $headerXpad4
		}
		.bg-animation {
			width: 100%;
			transform: translate3d(-10%, 0%,0);
		}
		.bg-animation .leaf {
			width: 40%;
		}
	}
}

@media (max-width: 767px) {
	.subscribe-form .switchbox-flex{
		font-size: 14px;
	}
	.animated-button .btn-text{
		font-size: 14px;
	}
	.animated-button:after, .animated-button:before{
		border-width: 4px;
	}
	.animated-button .btn-text{
		line-height: 30px;
		height: 30px;
		width: 130px;
	}
	.animated-button{
		width: 130px;
	    height: 30px;
    }
    .animated-button:after, .animated-button:before {
	    width: 14px;
	    height: 22px;
	}
	.bg-animation{
		width: 100%;
	    transform: translate3d(-30%,0,0);
	    max-width: 320px;
	}
	body, html {
	    font-size: 12px;
	}
	.dragable-slider .line{
		height: 1px;
		margin-top: 0;
	}
	body, html {
		// font-size: 14px;
	}
	.play-btn {
		width: 85px;
		height: 85px;
	}
	.c-social {
		width: 100%;
		left: 0;
		right: 0;
		bottom: $headerXpad6*2;
		top: auto;
		&:after,
		&:before {
			display: none;
		}
		> div {
			width: 100%;
			flex-direction: row;
			padding: 0 20px;
			justify-content: space-between
		}
		a {
			margin: 0 15px;
			opacity: 1;
		}
	}
	.section-content {
		.section-header {
			padding: 35px 20px 35px;
			.logo img {
				max-width: 135px;
				max-height: 70px;
			}
		}
		.content {
			// padding: $headerYpad6*2 + $menuiconHeight1 $headerXpad6;
			padding: 90px 20px 88px;
		}
	}
	.screen.screen-home .section-content .content{
		padding-bottom: 38px;
		padding-top: 84px;
	}
	.section-dragable {
		bottom: 40px;
	}
	.boxes-container {
		padding-left: 0;
		padding-right: 0;
		.row {
			margin-right: -10px;
			margin-left: -10px;
			.grid {
				padding: 10px;
			}
		}
	}
	.content-box {
		padding:  25px 12px;
		.box-logo {
			max-height: 60px;
			max-width: 110px;
		}
	}
	.dragable-cover {
		max-width: calc(100% - 70px)
	}
	.slide-label {
		font-size: 14px;
		margin: 8px 0 0;
	}
	.section-content .section-header .logo {
		left: $headerXpad6
	}
	.main-nav a {
		font-size: 22px;
		line-height: 1em;
		padding: 8px 0;
	}
	.subscribe-form {
	    margin-top: 10px;
	    padding: 15px 5px;
		> h3 {
			font-size: 19px;
			margin-bottom: 15px;
		}
		form {
		    padding: 0 20px;
		}
		.switchbox-flex{
			margin: 15px 0;
		}
	}
	.siteNavigation {
		width: 100%;
		bottom: 20px;
		a {
			font-size: 14px;
			margin: 0 5px;
		}
	}
	.subscribe-form {
		.onoffswitch {
			// width: 70px;
			.onoffswitch-inner{
				&:after {
					height: 30px;
					background-position: 45px;
					background-size: 16px;
				}
				&:before {
					height: 30px;
					background-position: 11px;
					background-size: 16px;
				}
			}
			.onoffswitch-switch {
				right: 32px;
			}
		}
	}
	.bg-animation .leaf {
		width: 100%;
	}
}
@media (max-width: 359px) {
	.subscribe-form .switchbox-flex{
		font-size: 10px;
	}
	.section-content {
		.section-header {
			padding: 20px $headerXpad6;
			.switch-lang {
				font-size: 18px;
			}
		}
		.content {
			// padding: $headerYpad6 + 33 $headerXpad6;
			padding: 63px 20px 96px;
		}
	}
	.content-box {
		padding:  15px 10px;
		.box-logo {
			max-height: 50px;
			max-width: 110px;
		}
		p {
			margin: 0
		}
	}
	.section-dragable {
		// bottom: 20px;
	}
	.slide-label {
		// display: none;
	}
	.dragable-slider {
		padding: 10px 0;
	}
	.dragable-cover:after, .dragable-cover:before {
		border-width: 16px;
		left: -32px;
	}
	.dragable-cover:after {
		left: auto;
		right: -32px;
	}
	.dragable-slider .ui-slider-handle:after, .dragable-slider .ui-slider-handle:before {
		border-width: 3px;
		width:  10px;
		height: 10px;
	}
	.dragable-slider .checkpoints .point {
		width: 10px;
		height: 10px;
		border: 3px solid #000;
	}
	.dragable-slider .line {
		width: calc(50% - 20px);
	}
	.dragable-slider .ui-slider-handle {
		width: 30px;
		height: 30px;
		margin-left: -15px;
		top: 1px;
	}
	.section-content .section-header .logo img {
		max-width: 105px;
		max-height: 50px;
	}
	.section-content .section-header .logo.logoCF{
		margin-top: 10px;
	}
	.subscribe-form {
		>h3 {
			font-size: 15px;
		}
		input {
			font-size: 13px;
		}
	}
	.main-nav a {
		font-size: 16px;
		padding: 7px 0;
	}
	.c-social {
		bottom: 20px;
	}
}

@media (max-width: 767px) and (max-height: 520px){
	body, html {
		font-size: 11px;
	}
	.screen-home{
		.paddedX p{
			margin-bottom: 0;
		}
	}
	.content-box{
		padding: 10px;
	}
}

.page-collection{
	perspective: 1200px;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: #000;
	position: relative;

	&.perspective-vertical{
		perspective: 1200px;
	}
}

#screenMenu, #screenMenu-ar{
	.section-content{
		transform: translateY(100%);
		transition: transform 0.5s ease-in-out;
	}
}

[class^=page-will-go-]{
	z-index: 1;
	opacity: 1;
}
[class^=page-will-go-front-from-]{
	z-index: 2;
	opacity: 1;
}

@keyframes rotateCubeTopOut{
	50% {
		animation-timing-function: ease-out;
		transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
		transform-origin: 50% 100%;
	}
	100% {
		/*opacity: .3;*/
		transform: translateY(-100%) rotateX(90deg);
		transform-origin: 50% 100%;
	}
}
@keyframes rotateCubeTopIn{
	0% {
		/*opacity: .3;*/
		transform: translateY(100%) rotateX(-90deg);
		transform-origin: 50% 0%;
	}
	50% {
		animation-timing-function: ease-out;
		transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
		transform-origin: 50% 0%;
	}
}
@keyframes rotateCubeBottomOut {
	50% {
		animation-timing-function: ease-out;
		transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
		transform-origin: 50% 0%;
	}
	100% {
		/*opacity: .3;*/
		transform: translateY(100%) rotateX(-90deg);
		transform-origin: 50% 0%;
	}
}
@keyframes rotateCubeBottomIn {
	0% {
		/*opacity: .3;*/
		transform: translateY(-100%) rotateX(90deg);
		transform-origin: 50% 100%;
	}
	50% {
		animation-timing-function: ease-out;
		transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
		transform-origin: 50% 100%;
	}
}


@keyframes rotateCubeLeftOut {
	50% {
		animation-timing-function: ease-out;
		transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
		transform-origin: 100% 50%;
	}
	100% {
		transform: translateX(-100%) rotateY(-90deg);
		transform-origin: 100% 50%;
	}
}
@keyframes rotateCubeLeftIn { 
	0% {
		transform: translateX(100%) rotateY(90deg);
		transform-origin: 0% 50%;
	}	
	50% {
		animation-timing-function: ease-out;
		transform: translateX(50%) translateZ(-200px) rotateY(45deg);
		transform-origin: 0% 50%;
	}
}

@keyframes rotateCubeRightOut {
	50%{
		animation-timing-function: ease-out;
		transform: translateX(50%) translateZ(-200px) rotateY(45deg);
		transform-origin: 0% 50%;
	}
	100% {
		transform: translateX(100%) rotateY(90deg);
		transform-origin: 0% 50%;
	}
}
@keyframes rotateCubeRightIn {
	0% {
		transform: translateX(-100%) rotateY(-90deg);
		transform-origin: 100% 50%;
	}
	50% {
		animation-timing-function: ease-out;
		transform: translateX(-50%) translateZ(-200px) rotateY(-45deg);
		transform-origin: 100% 50%;
	}
}
.page-base{
	overflow: hidden;
	height: 100%;
	display: none;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	z-index: 1;
	background: #fff;


	&.page--active{
		display: block;
		z-index: 2;

		&.remove-active-state{
			z-index: 3;
		}
		&.half-way-through{
			z-index: 1;
		}
	}
	
	&.rotateCubeTopOut {
	  transform-origin: 50% 100%;
	  animation: rotateCubeTopOut .6s both ease-in;
	  animation-delay: .1s;
	}

	&.rotateCubeTopIn {
	  transform-origin: 50% 0%;
	  animation: rotateCubeTopIn .6s both ease-in;
	  animation-delay: .1s;
	}

	&.rotateCubeBottomOut {
	  transform-origin: 50% 0%;
	  animation: rotateCubeBottomOut .6s both ease-in;
	  animation-delay: .1s;
	}

	&.rotateCubeBottomIn {
	  transform-origin: 50% 100%;
	  animation: rotateCubeBottomIn .6s both ease-in;
	  animation-delay: .1s;
	}

	
	&.rotateCubeLeftOut {
	  transform-origin: 100% 50%;
	  animation: rotateCubeLeftOut .6s both ease-in;
	  animation-delay: .1s;
	}

	&.rotateCubeLeftIn {
	  transform-origin: 0% 50%;
	  animation: rotateCubeLeftIn .6s both ease-in;
	  animation-delay: .1s;
	}

	&.rotateCubeRightOut {
	  transform-origin: 0% 50%;
	  animation: rotateCubeRightOut .6s both ease-in;
	  animation-delay: .1s;
	}

	&.rotateCubeRightIn {
	  transform-origin: 100% 50%;
	  animation: rotateCubeRightIn .6s both ease-in;
	  animation-delay: .1s;
	}
}

.is--ie{
	background: #fff;
	.page-base{
		display: block;
		transition: opacity .3s;
		animation: none !important;
		opacity: 0;
		z-index: 1;

		&.fadeOut{
			opacity: 0;
			z-index: 5;
		}
		&.fadeIn{
			opacity: 1;
			z-index: 3;
		}

		&.remove-active-state,
		&.page--active{
			z-index: 2;
		}
		&.page--active{
			opacity: 1;
		}
	}
}

.u-h-100{
	height: 100%;
}

@media (max-width: 767px) {
	.section-content .section-header .switch-lang{
		// font-size: 22px;
		// line-height: 1em;
	}
	.shrink-height-1{
		.section-dragable{
			bottom: 20px;
		}
		.slide-label{
			display: none;
		}
		.section-content .section-header {
		    padding: 15px;
		}
		.section-content .section-header .logo{
			left: 15px;
		}
		.section-content .content {
			padding-top: 61px;
			padding-bottom: 82px;
		}
		.section-content .section-header .logo img {
			max-height: 50px;
			margin-top: 20px;
		}
	}

	.shrink-height-2{
		.section-content .section-header .logo{
			top: 50%;
		}
		.section-dragable {
		    bottom: 10px;
		}
		.section-content .section-header{
			padding: 12px;
		}
		.section-content .section-header .logo{
			left: 12px;
		}
		.c-social a{
			width: 20px;
			height: 27px;
		}
		.play-btn {
		    width: 65px;
		    height: 65px;
		}
		.section-content .content .overflow-div{
			p{
				margin-bottom: 0;
			}
		}
		.section-content .content {
		    padding-top: 54px;
		    padding-bottom: 56px;
		}
		.dragable-slider .ui-slider-handle:after, .dragable-slider .ui-slider-handle:before{
			width: 12px;
			height: 12px;
			border-width: 4px;
		}
		.dragable-slider{
			padding: 12px 0;
		}
		.dragable-cover:after, .dragable-cover:before{
			border-width: 18px;
			left: -36px;
		}
		.dragable-cover:after{
			left: auto;
			right: -36px;
		}
	}
	.shrink-height-3{
		.dragable-cover:after, .dragable-cover:before{
			display: none;
		}
		.dragable-cover{
			background: transparent;
		}
		.screen-home .section-content:after, .screen-menu .section-content:after {
		    content: "";
		    width: 100%;
		    height: 100px;
		    position: absolute;
		    bottom: 0;
		    z-index: 2;
		    left: 0;
		    background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,.9) 50%);
		}
		.dragable-slider .checkpoints .point{
			background: #eee;
		}
		.screen-home .section-header, .screen-menu .section-header {
		    background: linear-gradient(180deg,#fff 0,hsla(0,0%,100%,0));
		}
	}
}

@media (max-width: 767px) and (max-height: 540px) {
	.dragable-cover:after, .dragable-cover:before{
		display: none;
	}
	.dragable-cover{
		background: transparent;
	}
	.section-dragable{
		bottom: 37px;
	}
	/* .screen-home .section-content:after{
	    content: "";
	    width: 100%;
	    height: 100px;
	    position: absolute;
	    bottom: 0;
	    z-index: 2;
	    left: 0;
	    background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,.9) 50%);
	} */
	.dragable-slider .checkpoints .point{
		background: #eee;
	}
	.screen-home .section-header, .screen-menu .section-header {
	    background: linear-gradient(180deg,#fff 0,hsla(0,0%,100%,0));
	}
}

/* ================================= */
/* AR CSS */
/* ================================= */
/* .ar-active{
	.slide-label{
		.ar{ display: block; }
		.en{ display: none; }
	}
} */

$loaderBorderWidth: 18px;
$loaderSize: 150px;

$loaderBorderWidthSM: 5px;
$loaderSizeSM: 38px;

.c-loader{
	position: fixed;
	z-index: 9999;
	background: #fff;
	top: 0;
	left: 0;
	width: 100%;
	width: 100vw;
	height: 100%;
	height: 100vh;

	.loader-txt{
		// font-family: arial, sans-serif;
		font-family: 'Effra', arial, sans-serif;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		text-transform: uppercase;
		padding: 40px;
		@media (max-width: 767px) {
			font-size: 18px;
		}

		span{
			display: inline-block;
			opacity: 0;
			transform: translateX(4px);
			transition: all .35s;

			&:nth-child(2){ transition-delay: .1s; }
			&:nth-child(3){ transition-delay: .2s; }
			&:nth-child(4){ transition-delay: .3s; }
			&:nth-child(5){ transition-delay: .4s; }
			&:nth-child(6){ transition-delay: .5s; }
			&:nth-child(7){ transition-delay: .6s; }
			&:nth-child(8){ transition-delay: .7s; }

			&:nth-child(9){ transition-delay:  .8s; }
			&:nth-child(10){ transition-delay: .9s; }
			&:nth-child(11){ transition-delay: 1.0s; }
			&:nth-child(12){ transition-delay: 1.1s; }
			&:nth-child(13){ transition-delay: 1.2s; }
			&:nth-child(14){ transition-delay: 1.3s; }
			&:nth-child(15){ transition-delay: 1.4s; }
			&:nth-child(16){ transition-delay: 1.5s; }
			&:nth-child(17){ transition-delay: 1.6s; }
			&:nth-child(18){ transition-delay: 1.7s; }
			&:nth-child(19){ transition-delay: 1.8s; }
			&:nth-child(20){ transition-delay: 1.9s; }
		}
	}
}
.is-ar{
	h1{
		direction: rtl;
	}
	p{
		direction: rtl;
	}
	.c-loader{
		.loader-txt{
			font-family: FrutigerLTArabic, arial, sans-serif;;
			direction: rtl;
			font-size: 24px;
			
			@media (max-width: 767px) {
				font-size: 18px;
			}

			span{
				&:nth-child(2){ transition-delay: .00s; }
				&:nth-child(3){ transition-delay: .038s; }
				&:nth-child(4){ transition-delay: .076s; }
				&:nth-child(5){ transition-delay: .114s; }
				&:nth-child(6){ transition-delay: .152s; }
				&:nth-child(7){ transition-delay: .19s; }
				&:nth-child(8){ transition-delay: .228s; }
				&:nth-child(9){ transition-delay: .266s; }
				&:nth-child(10){ transition-delay: .304s; }
				&:nth-child(11){ transition-delay: .342s; }
				&:nth-child(12){ transition-delay: .38s; }
				&:nth-child(13){ transition-delay: .418s; }
				&:nth-child(14){ transition-delay: .456s; }
				&:nth-child(15){ transition-delay: .494s; }
				&:nth-child(16){ transition-delay: .532s; }
				&:nth-child(17){ transition-delay: .57s; }
				&:nth-child(18){ transition-delay: .608s; }
			}
		}
	}


	.loading-done{
		.c-loader{
			.loader-txt{
				span{
					opacity: 0;
					transform: translateX(4px);

					&:nth-child(1){ transition-delay: 0.0s; }
					&:nth-child(2){ transition-delay: 0.02s; }
					&:nth-child(3){ transition-delay: 0.04s; }
					&:nth-child(4){ transition-delay: 0.06s; }
					&:nth-child(5){ transition-delay: 0.10s; }
					&:nth-child(6){ transition-delay: 0.12s; }
					&:nth-child(7){ transition-delay: 0.14s; }
					&:nth-child(8){ transition-delay: 0.16s; }
					&:nth-child(9){ transition-delay: 0.18s; }
					&:nth-child(10){ transition-delay: 0.20s; }
					&:nth-child(11){ transition-delay: 0.22s; }
					&:nth-child(12){ transition-delay: 0.24s; }
					&:nth-child(13){ transition-delay: 0.26s; }
					&:nth-child(14){ transition-delay: 0.28s; }
					&:nth-child(15){ transition-delay: 0.30s; }
					&:nth-child(16){ transition-delay: 0.32s; }
					&:nth-child(17){ transition-delay: 0.34s; }
					&:nth-child(18){ transition-delay: 0.36s; }
				}
			}
		}
	}
}
.start-loading{
	.c-loader{
		.loader-txt{
			span{
				opacity: 1;
				transform: translateX(0px);
			}
		}
	}
}
.loading-done{
	.c-loader{
		.loader-txt{
			span{
				opacity: 0;
				transform: translateX(4px);

				&:nth-child(1){ transition-delay: .05s; }
				&:nth-child(2){ transition-delay: .1s; }
				&:nth-child(3){ transition-delay: .15s; }
				&:nth-child(4){ transition-delay: .2s; }
				&:nth-child(5){ transition-delay: .25s; }
				&:nth-child(6){ transition-delay: .3s; }
				&:nth-child(7){ transition-delay: .35s; }
				&:nth-child(8){ transition-delay: .4s; }
			}
		}
	}
}

/* .c-box{
	width: $loaderSize;
	height: $loaderSize;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	z-index: 5;

	.side{
		width: $loaderBorderWidth;
		height: 0;
		position: absolute;
		top: 0;
		right: 0;
		overflow: hidden;
		
		animation-duration: 4s; 
		animation-timing-function: ease;
		animation-delay: 1s;
		animation-iteration-count: infinite;
		animation-fill-mode: forwards;
		animation-direction: normal;
		animation-name: loaderBoxRight;
		will-change: width, height, position;

		&:before{
			content: '';
			display: block;
			width: $loaderSize;
			height: $loaderSize;
			position: absolute;
			bottom: 0;
			right: 0;
			border: $loaderBorderWidth solid #000;
			border-top: 0;
			border-left: 0;
			box-sizing: border-box;
		}

		&.side-1{
			
		}
		&.side-2{
			right: auto;
			left: 0;
			animation-delay: 2s;
			bottom: 0;
			top: auto;
			animation-name: loaderBoxLeft;
			height: 0px;
			width: 0px;

			&:before{
				top: 0;
				bottom: auto;
				right: auto;
				left: 0;
				border: $loaderBorderWidth solid #000;
				border-bottom: 0;
				border-right: 0;
			}
		}
	}

	&.box--sm{
		width: $loaderSizeSM;
		height: $loaderSizeSM;

		.side{
			// width: $loaderBorderWidthSM;
			animation-name: loaderBoxRightSM;
			width: 100%;
			height: 100%;
	    	left: 0;
	    	right: auto;
	    	bottom: 0;
	    	top: auto;
			animation-delay: 0s;
			animation-duration: 2s;

			&:before{
				width: $loaderSizeSM;
				height: $loaderSizeSM;
				border: $loaderBorderWidthSM solid #000;
				border-top: 0;
				border-left: 0;
			}

			&.side-1{
			}

			&.side-2{
				animation-name: loaderBoxLeftSM;
		    	left: auto;
		    	right: 0;
		    	bottom: auto;
		    	top: 0;
				animation-delay: .5s;

				&:before{
					border: $loaderBorderWidthSM solid #000;
					border-bottom: 0;
					border-right: 0;
				}
			}
		}
	}
}

@keyframes loaderBoxRight{
  	0%{
    	width: $loaderBorderWidth;
    	height: 0%;
    	top: 0;
    	bottom: auto;
    	left: auto;
    	right: 0;
	}
  	12.5%{
    	width: $loaderBorderWidth;
    	height: 100%;
    }
  	25%{
    	width: 100%;
    	height: 100%;
    	top: 0;
    	bottom: auto;
    	left: auto;
    	right: 0;
    }
  	37.5%{
    	width: 100%;
    	height: 100%;
    	top: auto;
    	bottom: 0;
    	left: auto;
    	right: 0;
    }
  	50%{
    	width: 100%;
    	height: 100%;
    }
  	62.5%{
    	width: 100%;
    	height: $loaderBorderWidth;
    }
  	75%{
    	width: 0%;
    	height: $loaderBorderWidth;
    }
  	87.5%{
    	width: 0%;
    	height: 10%;
    	left: 0;
    	right: auto;
    }
  	100%{
    	width: 0%;
    	height: 10%;
    	top: auto;
    	bottom: 0;
    	left: 0;
    	right: auto;
    }
}

@keyframes loaderBoxLeft{
  	0%{
    	width: $loaderBorderWidth;
    	height: 0%;
    	top: auto;
    	bottom: 0;
    	left: 0;
    	right: auto;
	}
  	12.5%{
    	width: $loaderBorderWidth;
    	height: 100%;
    }
  	25%{
    	width: 100%;
    	height: 100%;
    	top: auto;
    	bottom: 0;
    	left: 0;
    	right: auto;
    }
  	37.5%{
    	width: 100%;
    	height: 100%;
    	top: 0;
    	bottom: auto;
    	left: 0;
    	right: auto;
    }
  	50%{
    	width: 100%;
    	height: 100%;
    }
  	62.5%{
    	width: 100%;
    	height: $loaderBorderWidth;
    }
  	75%{
    	width: $loaderBorderWidth;
    	height: $loaderBorderWidth;
    }
  	87.5%{
    	width: $loaderBorderWidth;
    	height: 10%;
    	left: auto;
    	right: 0;
    }
  	100%{
    	width: 0%;
    	height: 10%;
    	top: 0;
    	bottom: auto;
    	left: auto;
    	right: 0;
    }
} */


/* @keyframes loaderBoxRightSM{
  	0%{
    	width: 100%;
    	height: 100%;
    	left: 0;
    	right: auto;
    	bottom: 0;
    	top: auto;
    }
  	12.5%{
    	width: 100%;
    	height: $loaderBorderWidthSM;
    }
  	25%{
    	width: 0;
    	height: $loaderBorderWidthSM;
    }
  	37.5%{
    	width: 0%;
    	height: 0;
    	left: 0;
    	right: auto;
    	bottom: 0;
    	top: auto;
    }
  	50%{
    	width: $loaderBorderWidthSM;
    	height: 0;
    	top: 0;
    	bottom: auto;
    	right: 0;
    	left: auto;
    }
  	62.5%{
    	width: $loaderBorderWidthSM;
    	height: 100%;
	}
  	75%{
    	width: 100%;
    	height: 100%;
    }
  	87.5%{
    	width: 100%;
    	height: 100%;
    	top: 0;
    	bottom: auto;
    	left: auto;
    	right: 0;
    }
  	100%{
    	width: 100%;
    	height: 100%;
    	left: 0;
    	right: auto;
    	bottom: 0;
    	top: auto;
    }
}

@keyframes loaderBoxLeftSM{
  	0%{
    	width: 100%;
    	height: 100%;
    	left: auto;
    	right: 0;
    	bottom: auto;
    	top: 0;
    }
  	12.5%{
    	width: 100%;
    	height: $loaderBorderWidthSM;
    }
  	25%{
    	width: $loaderBorderWidthSM;
    	height: $loaderBorderWidthSM;
    }
  	37.5%{
    	width: 0;
    	height: 0;
    	left: auto;
    	right: 0;
    	bottom: auto;
    	top: 0;
    }
  	50%{
    	width: $loaderBorderWidthSM;
    	height: 0;
    	top: auto;
    	bottom: 0;
    	right: auto;
    	left: 0;
    }
  	62.5%{
    	width: $loaderBorderWidthSM;
    	height: 100%;
    	top: auto;
    	bottom: 0;
    	left: 0;
    	right: auto;
	}
  	75%{
    	width: 100%;
    	height: 100%;
    }
  	87.5%{
    	width: 100%;
    	height: 100%;
    	top: auto;
    	bottom: 0;
    	left: 0;
    	right: auto;
    }
  	100%{
    	width: 100%;
    	height: 100%;
    	left: auto;
    	right: 0;
    	bottom: auto;
    	top: 0;
    }
} */

.linePreloader{
	width:200px;
	height:4px;
	background:linear-gradient(to right,black,black);
	background-color:#ccc;
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	margin:auto;
	border-radius:4px;
	background-size:20%;
	background-repeat:repeat-y;
	background-position:-25% 0;
	animation:scroll 1.2s ease-in-out infinite;
	z-index: 1;
}

@keyframes scroll{
	50%{background-size:80%}
	100%{background-position:125% 0;}
}

.c-video-background{
    min-height: calc(100% + 2px);
    position: absolute;
    left: -1px;
    right: -1px;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 1;
    &:before{
    		content: "";
    		display: block;
    		height: 0px;
    		padding-bottom: 57.1428571429%;
    }
    /* &:after{
    	content:'';
    	display: block;
    	position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
    	z-index: 10;
    } */
    img{
    	display: none;
    }
    video{
		width: auto;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 50%;
		top: 0px;
		transform: translate(-50%, 0%);
    }
}

.c-main-mdoule{
	display: flex;
	justify-content: space-between;
	width: 100%;
	text-align: left;
	height: 100%;
	align-items: center;
	overflow-y: auto;
	overflow-x: hidden;
    max-width: 1260px;
    margin: 0 auto;

	@media (max-width: 992px) {
	    flex-direction: column;
	    justify-content: flex-start;
	}
	@media (max-width: 767px) {
	    justify-content: center;
	}

	> * {
		width: 50%;

		@media (max-width: 992px) {
			width: 100%;
		}
	}

	.left, .right{
		max-height: 100%;
		@media (max-width: 992px) {
			max-height: none;
		}
	}

	.left{
		max-width: 570px;
		padding-right: 60px;

	    /* @media (max-width: 1820px) {
	    	max-width: 675px;
	    }
	    @media (max-width: 1720px) {
	    	max-width: 660px;
	    }
	    @media (max-width: 1620px) {
	    	max-width: 640px;
	    }
	    @media (max-width: 1520px) {
	    	max-width: 600px;
	    } */
	    @media (max-width: 1420px) {
	    	max-width: 440px;
	    }
		@media (max-width: 992px) {
			padding-right: 0;
			max-width: 470px;
			order: 2;
		}
	}
	.right{
		height: 100%;
		@media (max-width: 992px) {
			height: auto;
		}
		@media (max-width: 767px) {
			display: none;
		}
	}
	.highlight-img{
		height: 100%;
	    display: flex;
	    align-items: center;
		@media (max-width: 992px) {
			max-width: 500px;
			height: auto;
			margin: 0 auto 50px;
		}
		@media (max-width: 767px) {
			max-width: 260px;
			margin-bottom: 25px;
		}
		img{
			display: block;
			margin: 0 auto;
			height: auto;
			max-height: 100%;
			width: auto;
			max-width: 100%;
			// min-height: 300px;

			@media (max-width: 767px) {
				min-height: 0;
			}

		}
	}

	h1{
		font-size: 26px;
		font-weight: 700;
		line-height: 1.2em;
		text-transform: uppercase;
		margin-bottom: 20px;

	    @media (max-width: 1820px) {
	    	font-size: 25px;
	    }
	    @media (max-width: 1720px) {
	    	font-size: 24px;
	    }
	    @media (max-width: 1620px) {
	    	font-size: 23px;
	    	// margin-bottom: 50px;
	    }
	    @media (max-width: 1520px) {
	    	font-size: 22px;
	    }
	    @media (max-width: 1420px) {
	    	font-size: 21px;
	    	line-height: 1.4em;
	    	// margin-bottom: 45px;
	    }
		@media (max-width: 767px) {
		    font-size: 18px;
		    line-height: 1.2em;
		    // margin-bottom: 50px;
		    // letter-spacing: 2px;
		}
		@media (max-width: 339px) {
			font-size: 16px;
		}
	}
	.action{
		margin-top: 40px;
		img{
		    @media (max-width: 1420px) {
		    	max-height: 80px;
		    }
			@media (max-width: 767px) {
				max-height: 70px;
			}
		}
		a{
			margin-right: 120px;
		    @media (max-width: 1420px) {
		    	margin-right: 70px;
		    }
			@media (max-width: 767px) {
				margin-right: 50px;

				&[data-topage="screenQAH-ar"],
				&[data-topage="screenQAH"]{
					img{
						width: 100px;
					}
				}

				&[data-topage="screenCF-ar"],
				&[data-topage="screenCF"]{
					img{
						width: 124px;
					}
				}
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}
}


mark {
    background-color: transparent;
    white-space: nowrap;
    color: inherit;
}


.screen-CF,
.screen-QAH{
	.content-wrap{
		height: 100%;
		overflow: auto;
	}
	.section-content .section-header .cta-menu i{
		@media (max-width: 1023px) {
			background: #fff;
		}
	}
	.section-bg{
		width: 70%;
		overflow: hidden;

		@media (max-width: 1700px) {
			width: 65%;
		}
		@media (max-width: 1320px) {
			width: 65%;
		}
		@media (max-width: 1420px) {
			width: 60%;
		}
		@media (max-width: 1023px) {
			width: 100%;
			position: relative;
			height: 80%;
		}

		.c-bg-carousel{
			.slick-slide{
				@media (max-width: 1023px){
					height: 100%;
				}
			}
			.item{
				width: 100%;
				@media (max-width: 1023px){
					height: 80vh;
				}
			}
			img{
				width: 70vh;
				transition: none;

				@media (max-width: 1700px) {
					width: 65vw;
				}
				@media (max-width: 1320px) {
					width: 65vw;
				}
				@media (max-width: 1420px) {
					width: 60vw;
				}
				@media (max-width: 1023px) {
					width: 80vw;
				}
				@media (max-width: 1023px){
					width: 100%;
					width: 100vw;
					height: 80vh;
				}
			}
		}
	}
	&.page--active{
		.section-bg{
			.c-bg-carousel{
				img{
					width: 100%;
				}
			}
		}
	}
	.section-content-1{
		width: 30%;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 20px;

		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 250px 80px 20px 80px;
		padding: 25vh  80px 20px  80px;
		
		@media (max-width: 1700px) {
			width: 35%;
		}
		@media (max-width: 1420px) {
			width: 40%;
		}
		@media (max-width: 1023px) {
			width: 100%;
			position: static;
			padding: 80px;
			height: auto;
		}
		@media (max-width: 767px) {
			padding: 25px;
		}

		.wrap{
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
		}

		h1{
			font-size: 40px;
		    font-weight: 700;
		    line-height: 1.2em;
		    text-transform: uppercase;
		    letter-spacing: 5px;
		    margin-bottom: 25px;

		    @media (max-width: 1820px) {
		    	font-size: 38px;
		    }
		    @media (max-width: 1720px) {
		    	font-size: 36px;
		    }
		    @media (max-width: 1620px) {
		    	font-size: 32px;
		    }
		    @media (max-width: 1520px) {
		    	font-size: 28px;
		    }
		    @media (max-width: 1420px) {
		    	font-size: 22px;
		    	line-height: 1.4em;
		    	margin-bottom: 30px;
		    }
			@media (max-width: 767px) {
			    font-size: 20px;
			    line-height: 1.2em;
			    margin-bottom: 20px;
			    letter-spacing: 2px;
			}
			@media (max-width: 339px) {
				font-size: 18px;
			}
		}
		p{
			font-size: 16px;
			line-height: 1.6em;

		    @media (max-width: 1820px) {
		    	font-size: 14px;
		    }
		}
	}
}

.screen-menu{
	.section-content .content .overflow-div{
		text-align: left;
		flex-direction: row;
		max-width: 1200px;
		margin: 0 auto;
		@media (max-width: 1023px){
			flex-direction: column;
		}
	}
}

@media (max-width: 767px){
	.screen.screen-menu .section-content .content .overflow-div{
		margin: -20px;
		padding: 20px;
		width: calc(100% + 40px);
		min-width: 100%;
	}
}

.c-btn{
	display: inline-block;
	color: #fff;
	background: #000;
	font-size: 18px;
	text-transform: uppercase;
	line-height: 1em;
	padding: 10px 20px;
	min-width: 150px;
	font-weight: bold;
	text-align: center;
	@media (max-width: 767px){
		font-size: 15px;
		min-width: 130px;
		padding: 8px 18px;
	}
}

.from-tablet-port{
	display: none !important;
	@media (max-width: 1023px) {
		display: block !important;
	}
}
.before-tablet-port{
	@media (max-width: 1023px) {
		display: none !important;
	}
}























/* ==================================== */
/* Video Play Button */
/* ==================================== */

.c-btn-video {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-decoration: none;
    color: #fff;
    border: 1px solid #000;
    padding: 15px 30px;
    font-size: 16px;
    line-height: 1em;
    font-family: arial;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.2);
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
.c-btn-video + .c-btn-video {
    margin-top: 100px;
}
.c-btn-video:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 7px solid #fff;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    margin-right: 15px;
    position: relative;
    left: 0;
    top: 2px;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
.c-btn-video:hover {
    background-color: rgba(0, 0, 0, 0.7);
}
.c-btn-video:hover:before {
    /*left: 2px;*/
}
/* ==================================== */
/* END - Video Play Button */
/* ==================================== */




/* ==================================== */
/* Popup CSS */
/* ==================================== */

.c-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;

    video{
		width: 100%;
		height: 100%;
		background: #000;
    }
}

.c-popup .overlay {
    display: block;
    height: 100%;
    width: 100%;
    background: transparent;
    transition: all .3s;
}

.c-popup.popup--open .overlay {
    background: rgba(0, 0, 0, 0.5);
}

.c-popup .popup {
    display: none;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 740px;
    padding: 20px;
    max-height: 100%;
    width: 100%;
    opacity: 0;
    top: 40%;
    transition: all .3s 0s;
    overflow-y: auto;
}

.c-popup .popup.popup--full-screen {
    top: 0;
    left: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
    height: 100%;
    padding: 0;
    max-width: none;
}

.c-popup .popup.popup--full-screen.active {
    top: 0;
}

.c-popup .popup.popup--full-screen .popup-wrap {
    padding: 0;
    height: 100%;
}

.c-popup .popup.popup--full-screen .popup-wrap .plyr {
    height: 100%;
}

.c-popup .popup .popup-wrap {
    background: #fff;
    padding: 20px;
}

.c-popup .popup.active {
    top: 50%;
    opacity: 1;
    transition-delay: .3s;
}

.c-popup .popup.popup--full-screen .popup-action {
	position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    transform: none;
    margin: 0;
    padding: 15px 15px 35px 35px;
    background: #000;
    border-radius: 0 0 0 999px;
}

.c-popup .title {
    font-size: 1.5625em;
    line-height: 1.3em;
    margin: 0 0 15px;
    font-weight: bold;
    color: #000;
}

.c-popup .txt-wrap {
    max-width: 500px;
    margin: 0 auto;
}
.c-popup{
	.cta-menu{
		&.menu--close{
			i{
				background: #fff;
			}
		}
	}
}
.c-popup .deco {
    display: block;
    margin: 5px 0 15px 0;
    border: none;
    position: relative;
    height: 1px;
    background: #9f9a9a;
}

.c-popup .deco:after {
    content: '';
    display: block;
    width: 80px;
    height: 6px;
    background: #000;
    position: absolute;
    bottom: 0px;
    left: 20px;
}

.c-popup .popup-action {
    text-align: center;
    margin-top: 20px;
}
.c-popup .js-video{
    height: 100%;
}

/* ==================================== */
/* END - Popup CSS */
/* ==================================== */




/* ==================================== */
/* Popup Close BUtton  */
/* ==================================== */

.c-close {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid #000;
    border-radius: 999px;
    position: relative;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff;
    transition: all .3s;
    box-sizing: border-box;
}
.c-close span {
    display: none;
}
.c-close:before,
.c-close:after {
    content: '';
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 11px;
    left: 15%;
    height: 1px;
    width: 70%;
    background: #000;
    transition: all .3s;
}
.c-close:after {
    top: 15%;
    left: 11px;
    height: 70%;
    width: 1px;
}
.c-close:hover {
    background: #000;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.c-close:hover:before,
.c-close:hover:after {
    background: #fff;
}
.c-close:active {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
}
/* ==================================== */
/* END - Popup Close BUtton  */
/* ==================================== */
/* Some responsive css for close button and popup */

@media (max-width: 767px) {
    /* .c-close {
        width: 20px;
        height: 20px;
    }
    .c-close:after {
        left: 9px;
    }
    .c-close:before {
        top: 9px;
    } */
    .c-popup .deco:after {
        width: 60px;
        height: 4px;
        left: 15px;
    }
    .c-popup .popup .popup-wrap {
        padding: 15px;
    }
    .popup .c-form-1 {
        position: static;
        margin: 0;
    }
    .popup .c-form-1 .wrap {
        padding: 0;
    }
    .popup .c-form-1 select,
    .popup .c-form-1 .select2-container {
        width: 100% !important;
    }
}
/* This will keep the video to be complete visible rather than partially! */
.plyr__video-wrapper{
    height: 100%;
    padding: 0 !important;
}